import './Scss/OneService.scss';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';

const OneService = ({ english, russian, uzbek, dataServices, changeModal }) => {

    const [nameValue, setNameValue] = useState("");
    const [numberValue, setNumberValue] = useState("");
    const [showContact, setShowContact] = useState(false);
    const [invalidName, setInvalidName] = useState(false);
    const [invalidNumber, setInvalidNumber] = useState(false);

    function changeName(e) {
        setNameValue(e.target.value)
        setInvalidName(false)
    }

    function changeNumber(e) {
        setNumberValue(e.target.value)
        setInvalidNumber(false)
    }

    let bot = {
        TOKEN: "5786613465:AAEPOBCMX9CAfl7W200DqTeEBS0PZ1-0pz4",
        chatID: "-1001688527588",
        message: `Ismi: ${nameValue}; %0ATelefon raqami: ${numberValue}`
    }

    function sendMessage() {
        if (nameValue === "") {
            setInvalidName(true)
        } else if (numberValue === "") {
            setInvalidNumber(true)
        } else {
            fetch(`https://api.telegram.org/bot${bot.TOKEN}/sendMessage?chat_id=${bot.chatID}&text=${bot.message} `, {
                method: "GET"
            })
                .then(success => {
                    console.log('send message')
                }, error => {
                    console.log(error)
                })

            changeModal()
            setNameValue("")
            setNumberValue("")
            setShowContact(!showContact)
        }
    }

    const { id } = useParams();

    const {t} = useTranslation();

    return (
        <>
            <div className='OneService'>
                <div className="links">
                    <a href="/#">{t("home")} <i className="fa fa-angle-double-right"></i></a>
                    <a href="#">{t("xizmat_page")}</a>
                </div>
                {dataServices.filter((c) => c.id == id).map((data) => (
                    <div className="wrapper" key={data.id}>
                        <h1 className="title">{english && data.name_en}{russian && data.name_ru}{uzbek && data.name_uz}</h1>
                        <p className="desc">{english && data.text_en}{russian && data.text_ru}{uzbek && data.text_uz}</p>
                        <img src={data.photo} alt="img" className="img" />
                        <div className="social">
                            <button className='support' onClick={() => setShowContact(!showContact)}>{t("buyurtma")}</button>
                            <div className="icons">
                                <a href="https://m.facebook.com/profile.php?id=100081633141407">
                                    <i className="fa fa-facebook"></i>
                                </a>
                                <a href="https://www.instagram.com/chinwakil/">
                                    <i className="fa fa-instagram"></i>
                                </a>
                                <a href="https://www.youtube.com/channel/UCwKwM9tDu3PZrdtlT3aYVwQ">
                                    <i className="fa fa-youtube-play"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={`service-contact ${!showContact && "d-none"}`}>
            <div className={`contrast ${!showContact && "d-none"}`} onClick={() => setShowContact(!showContact)}></div>
                <div className={`contact`}>
                    <Close className='close-icon' onClick={() => setShowContact(!showContact)} />
                    {dataServices.filter((c) => c.id == id).map((data) => (
                        <div key={data.id} className="header">
                            <img src={data.photo} alt="serv" />
                            <div className="service-text">
                                <h1 className="name">{english && data.name_en}{russian && data.name_ru}{uzbek && data.name_uz}</h1>
                                <p className="desc">{english && data.text_en.slice(0, 80)}{russian && data.text_ru.slice(0, 80)}{uzbek && data.text_uz.slice(0, 80)}...</p>
                            </div>
                        </div>
                    ))}
                    <div className="col-10 texts">
                        <h1 className="title">{t("form_chap_katta")}</h1>
                        <p className="desc">{t("form_chap")}</p>
                    </div>
                    <div className='col-12 form'>
                        <div className="body">
                            <input type="text" value={nameValue} className={`form-control ${invalidName && "red-line"}`} onChange={(e) => changeName(e)} placeholder={`${t("name")}`} />
                            <input type="number" value={numberValue} className={`form-control number ${invalidNumber && "red-line"}`} onChange={(e) => changeNumber(e)} placeholder={`${t("phone")}`} />
                            <button onClick={() => sendMessage()} className='send'>{t("send")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OneService;