import './App.scss';
import axios from 'axios';
import Add from './components/OurTeam/Add';
import Modal from './components/Modal/Modal';
import About from './components/About/About';
import News from './components/LastNews/News';
import { useTranslation } from 'react-i18next';
import Header from './components/Header/Header';
import Navbar from './components/Navbar/Navbar';
import Loader from './components/Loader/Loader';
import Footer from './components/Footer/Footer';
import React, { useEffect, useState } from 'react';
import OurTeam from './components/OurTeam/OurTeam';
import ScrollY from './components/Services/ScrollY';
import AboutHome from './components/About/AboutHome';
import NotFound from './components/NotFound/NotFound';
import Contacts from './components/Contacts/Contacts';
import Services from './components/Services/Services';
import LastNews from './components/LastNews/LastNews';
import Partners from './components/Partners/Partners';
import Advantage from './components/Advantage/Advantage';
import Tradition from './components/Tradition/Tradition';
import OneService from './components/Services/OneService';
import SliderPage from './components/SliderPage/SliderPage';
import SearchProd from './components/SearchProd/SearchProd';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import TextAnimation from './components/Advantage/TextAnimation';
import ProductTypes from './components/ProductTypes/ProductTypes';
import SuspenseLoader from './components/SuspenseLoader/SuspenseLoader';

const App = () => {

  // urls of API

  const baseUrl = `https://api.chinwakil.com/api`;
  const urlProductTypes = `${baseUrl}/product_types`;
  const urlsearch = `${baseUrl}/service_sliders`;
  const urlTradition = `${baseUrl}/traditions`;
  const urlAdvertising = `${baseUrl}/reklama`;
  const urlServices = `${baseUrl}/services`;
  const urlPartners = `${baseUrl}/partners`;
  const urlSliders = `${baseUrl}/sliders`;
  const urlNews = `${baseUrl}/articles`;
  const urlTeams = `${baseUrl}/taems`;
  const urlTypes = `${baseUrl}/types`;

  // Sliders API

  const [dataSliders, setDataSliders] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlSliders);
      setDataSliders(request.data);
      return request;
    };
    fetchData()
  }, [urlSliders]);

  // Services API

  const [dataServices, setDataServices] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlServices);
      setDataServices(request.data);
      return request;
    };
    fetchData()
  }, [urlServices]);

  // Partners API

  const [dataPartners, setDataPartners] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlPartners);
      setDataPartners(request.data);
      return request;
    };
    fetchData()
  }, [urlPartners]);

  // Teams API

  const [dataTeams, setDataTeams] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlTeams);
      setDataTeams(request.data);
      return request;
    };
    fetchData()
  }, [urlTeams]);

  // News API

  const [dataNews, setDataNews] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlNews);
      setDataNews(request.data);
      return request;
    };
    fetchData()
  }, [urlNews]);

  // Advertising API

  const [dataAdvertising, setDataAdvertising] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlAdvertising);
      setDataAdvertising(request.data);
      return request;
    };
    fetchData()
  }, [urlAdvertising]);

  // Slider-types API

  const [dataTypes, setDataTypes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlTypes);
      setDataTypes(request.data);
      return request;
    };
    fetchData()
  }, [urlTypes]);

  // Product-types API

  const [dataProductTypes, setDataProductTypes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlProductTypes);
      setDataProductTypes(request.data);
      return request;
    };
    fetchData()
  }, [urlProductTypes]);

  // Search-slider API

  const [datasearchSlider, setDatasearchSlider] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlsearch);
      setDatasearchSlider(request.data);
      return request;
    };
    fetchData()
  }, [urlsearch]);

  // Traditions API

  const [dataTraditions, setDataTraditions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(urlTradition);
      setDataTraditions(request.data);
      return request;
    };
    fetchData()
  }, [urlTradition]);

  // languages

  const { i18n } = useTranslation();
  const [uzbek, setUzbek] = useState(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') == "uz" ? true : false : false);

  const [english, setEnglish] = useState(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') == "en" ? true : false : false);
  const [russian, setRussian] = useState(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') == "ru" ? true : false : false);
  const [langTitle, setLangTitle] = useState(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').toUpperCase() : "")

  function changeUzbek(item) {
    setUzbek(item)
    setEnglish(!item)
    setRussian(!item)
    i18n.changeLanguage("uz")
    setLangTitle(localStorage.getItem('i18nextLng').toUpperCase())
  }

  function changeRussian(item) {
    setUzbek(!item)
    setRussian(item)
    setEnglish(!item)
    i18n.changeLanguage("ru")
    setLangTitle(localStorage.getItem('i18nextLng').toUpperCase())
  }

  function changeEnglish(item) {
    setUzbek(!item)
    setEnglish(item)
    setRussian(!item)
    i18n.changeLanguage("en")
    setLangTitle(localStorage.getItem('i18nextLng').toUpperCase())
  }

  // modal

  const [showModal, setShowModal] = useState(false)

  function changeModal() {
    setShowModal(!showModal)
  }

  // scrolls

  const scrollToLocation = (item) => {
    const { hash } = window.location;
    if (hash !== '') {
      let retries = 0;
      const id = hash.replace('#', '');
      const scroll = () => {
        retries += 0;
        if (retries > 50) return;
        const element = document.getElementById(id);
        if (element) {
          setTimeout(() => element.scrollIntoView(), 0);
        } else {
          setTimeout(scroll, 100);
        }
      };
      if (!item) {
        scroll();
      }
    }
  }

  scrollToLocation();

  // loader

  const [loaderShow, setLoaderShow] = useState(false);

  useEffect(() => {
      setLoaderShow(true)
  }, [])

  return (
    <BrowserRouter>
      {loaderShow && 
        <Loader /> 
      }
      <ScrollToTop />
      <Navbar uzbek={uzbek} russian={russian} english={english} changeUzbek={changeUzbek} changeEnglish={changeEnglish} changeRussian={changeRussian} langTitle={langTitle} />
      <Switch>
        <Route exact path="/">
          <Header uzbek={uzbek} russian={russian} english={english} dataSliders={dataSliders} />
          <ScrollY />
          <Services uzbek={uzbek} russian={russian} english={english} dataServices={dataServices} />
          <AboutHome />
          <SliderPage uzbek={uzbek} russian={russian} english={english} dataTypes={dataTypes} />
          <LastNews uzbek={uzbek} russian={russian} english={english} dataNews={dataNews} other={false} id={null} />
          <SearchProd uzbek={uzbek} russian={russian} english={english} dataSlider={datasearchSlider} />
          <ProductTypes uzbek={uzbek} russian={russian} english={english} dataAdvertising={dataAdvertising} dataProductTypes={dataProductTypes} />
          <Add />
          <OurTeam dataTeams={dataTeams} />
          <Tradition uzbek={uzbek} russian={russian} english={english} dataTraditions={dataTraditions} />
          <Partners dataPartners={dataPartners} />
          <TextAnimation uzbek={uzbek} russian={russian} english={english} />
          <Advantage uzbek={uzbek} russian={russian} english={english} />
          <Contacts changeModal={changeModal} />
          <Footer />
        </Route>
        <Route path="/services/:id">
          <OneService uzbek={uzbek} russian={russian} english={english} dataServices={dataServices} changeModal={changeModal} />
          <Contacts changeModal={changeModal} />
          <Footer />
        </Route>
        <Route path="/about">
          <About uzbek={uzbek} russian={russian} english={english} />
          <Contacts changeModal={changeModal} />
          <Footer />
        </Route>
        <Route path="/news/:id">
          <News uzbek={uzbek} russian={russian} english={english} dataNews={dataNews} />
          <Contacts changeModal={changeModal} />
          <Footer />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
      {showModal &&
        <Modal uzbek={uzbek} russian={russian} english={english} changeModal={changeModal} />
      }
    </BrowserRouter>
  );
};

export default App;