import React from 'react';
import Slider from 'react-slick';
import './Scss/ProductTypes.scss';
import { useTranslation } from 'react-i18next';

const ProductTypes = ({ english, russian, uzbek, dataAdvertising, dataProductTypes }) => {

    const settings = {
        dots: true,
        speed: 1000,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        cssEase: "linear",
        slidesToScroll: 1,
        slidesToScroll: 1,
        autoplaySpeed: 5000,
        className: "slider variable-width"
    };

    const {t} = useTranslation();

    return (
        <div className={`ProductTypes`} id='ProductTypes'>
            <div className="wrapper">
                <h1 className="title" >{t("tovarlar")}</h1>
                <div className="types">
                    {dataProductTypes.map((data) => (
                        <div key={data.id} className="col-6">
                            <a href={data.link} className="body">
                                <img src={data.photo} alt="type" />
                                <p className="type-name">{english && data.name_en} {russian && data.name_ru} {uzbek && data.name_uz}</p>
                            </a>
                        </div>
                    ))}
                </div>
                <Slider {...settings} className='carousel'>
                    {dataAdvertising.map((data) => (
                        <div key={data.id} className="carousel-page">
                            <img src={data.photo} alt="image" />
                            <div className="texts">
                                <p className="name">{english && data.name_en} {russian && data.name_ru} {uzbek && data.name_uz}</p>
                                <h1 className="desc">{english && data.description_en} {russian && data.description_ru} {uzbek && data.description_uz}</h1>
                                <div className="link">
                                    <a href={data.url} className='explore'>{t("explore")} <i className="fa fa-angle-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default ProductTypes;