import './Scss/TextAnimation.scss';
import { useTranslation } from 'react-i18next';
import Img1 from '../../assets/images/logo.png';
import React, { useState, useEffect } from 'react';

const TextAnimation = () => {

    const [scroll, setScroll] = useState(0);

    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                window.addEventListener("scroll", () => {
                    if(window.scrollY - entry.target.offsetTop < 2 * window.innerWidth){
                        setScroll(window.scrollY - entry.target.offsetTop + window.innerHeight*2/3)                                       
                    }
                })
            })
        },        
    );

    useEffect(() => {        
        const parent = document.getElementById("parent2")        
        observer.observe(parent)
    }, []);

    const {t} = useTranslation();

    return (
        <div className='TextAnimation' id="parent2">
            <h1 style={{marginLeft: `-${scroll/2}px`}} className='top-text'>{t("afzallik")}</h1>
            <img src={Img1} alt="logo" />
            <h1 style={{marginLeft: `${scroll/2}px`}} className='bottom-text'>{t("afzallik")}</h1>
        </div>
    );
};

export default TextAnimation;