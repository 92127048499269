import './Scss/Advantage.scss';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { CheckBox, ConnectWithoutContact, Handshake, PriceChange, Sell, Verified, AccountBalanceWallet, ControlPoint, MonetizationOn } from '@mui/icons-material';

const Advantage = () => {

    const [scroll, setScroll] = useState(0);
    const [fixed, setFixed] = useState(false);

    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                window.addEventListener("scroll", () => {
                    if (window.scrollY - entry.target.offsetTop > 0 && window.scrollY - entry.target.offsetTop < 2 * window.innerWidth) {
                        setScroll(window.scrollY - entry.target.offsetTop)
                        setFixed(true)
                    } else if (window.scrollY - entry.target.offsetTop <= 0 || window.scrollY - entry.target.offsetTop >= 2 * window.innerWidth) {
                        setFixed(false)
                    }
                })
            })
        }
    );

    useEffect(() => {
        const advantage = document.getElementById("advantage");
        observer.observe(advantage);
    }, []);

    const settings = {
        dots: true,
        speed: 1000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: "linear",
        pauseOnHover: false,
        autoplaySpeed: 4000,
        className: "slider variable-width"
    };

    const { t } = useTranslation();

    return (
        <div className={`Advantage ${fixed ? "full-page" : ""}`} id='advantage'>
            <div id='scroll' className={`scroll scroll1`} style={{ top: 0 }}>
                <div className="horizontal col-12">
                    <div className="body">                                              
                        <ul className="texts">
                            <h1 className="title">{t("adv")}</h1>
                            <li className="name"><Handshake className='icon' /> {t("a1")}</li>
                            <li className="name"><CheckBox className='icon' /> {t("a2")}</li>
                            <li className="name"><Sell className='icon' /> {t("a3")}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id='scroll' className={`scroll`} style={{ transform: `translateX(-${scroll}px)` }}>
                <div className="horizontal col-12">
                    <div className="body">                                              
                        <ul className="texts">
                            <h1 className="title">{t("adv")}</h1>
                            <li className="name"><Handshake className='icon' /> {t("a1")}</li>
                            <li className="name"><CheckBox className='icon' /> {t("a2")}</li>
                            <li className="name"><Sell className='icon' /> {t("a3")}</li>
                        </ul>
                    </div>
                </div>
                <div className="horizontal col-12">
                    <div className="body">                        
                        <ul className="texts">
                            <h1 className="title">{t("adv")}</h1>
                            <li className="name"><PriceChange className='icon' /> {t("a4")}</li>
                            <li className="name"><ConnectWithoutContact className='icon' /> {t("a5")}</li>
                            <li className="name"><Verified className='icon' /> {t("a6")}</li>
                        </ul>
                    </div>
                </div>
                <div className="horizontal col-12">
                    <div className="body">                        
                        <ul className="texts">
                            <h1 className="title">{t("adv")}</h1>
                            <li className="name"><ControlPoint className='icon' /> {t("a7")}</li>
                            <li className="name"><CheckBox className='icon' /> {t("a8")}</li>
                            <li className="name"><MonetizationOn className='icon' /> {t("a9")}</li>
                            <li className="name"><AccountBalanceWallet className='icon' /> {t("a10")}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Slider {...settings} id='scroll' className={`scroll slider-scroll`}>
                <div className="horizontal col-12">
                    <div className="body">                                              
                        <ul className="texts">
                            <h1 className="title">{t("adv")}</h1>
                            <li className="name"><Handshake className='icon' /> {t("a1")}</li>
                            <li className="name"><CheckBox className='icon' /> {t("a2")}</li>
                            <li className="name"><Sell className='icon' /> {t("a3")}</li>
                        </ul>
                    </div>
                </div>
                <div className="horizontal col-12">
                    <div className="body">                        
                        <ul className="texts">
                            <h1 className="title">{t("adv")}</h1>
                            <li className="name"><PriceChange className='icon' /> {t("a4")}</li>
                            <li className="name"><ConnectWithoutContact className='icon' /> {t("a5")}</li>
                            <li className="name"><Verified className='icon' /> {t("a6")}</li>
                        </ul>
                    </div>
                </div>
                <div className="horizontal col-12">
                    <div className="body">                        
                        <ul className="texts">
                            <h1 className="title">{t("adv")}</h1>
                            <li className="name"><ControlPoint className='icon' /> {t("a7")}</li>
                            <li className="name"><CheckBox className='icon' /> {t("a8")}</li>
                            <li className="name"><MonetizationOn className='icon' /> {t("a9")}</li>
                            <li className="name"><AccountBalanceWallet className='icon' /> {t("a10")}</li>
                        </ul>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default Advantage;