import './Scss/OurTeam.scss';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

const OurTeam = ({ dataTeams }) => {

    const [scroll, setScroll] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollTopWrapper, setScrollTopWrapper] = useState(0);

    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                window.addEventListener("scroll", () => {
                    if(window.scrollY - entry.target.offsetTop < 2 * window.innerWidth){
                        setScroll(window.scrollY - entry.target.offsetTop + window.innerHeight*2/3)                                       
                    }

                    if(window.scrollY - entry.target.offsetTop > 0){                        
                        setScrollTop(window.scrollY - entry.target.offsetTop)                                       
                    }
                    if(window.scrollY - entry.target.offsetTop < 0){                        
                        setScrollTop(0)                                       
                    }
                    
                    if(235 + window.innerHeight/5 - (window.scrollY - entry.target.offsetTop)/6 < 0){                                                             
                        // setScrollTopWrapper(235 + window.innerHeight/5 - (window.scrollY - entry.target.offsetTop)/6)
                        setScrollTopWrapper(-100)
                    }
                    if(235 + window.innerHeight/5 - (window.scrollY - entry.target.offsetTop)/6 >= 0){                                                           
                        setScrollTopWrapper(0)
                    } 
                })
            })
        },        
    );

    useEffect(() => {        
        const parent = document.getElementById("Add")        
        observer.observe(parent)
    }, []);

    const {t} = useTranslation();

    return (
        <div className='OurTeam' id="parent1" style={{top: window.innerWidth > 767 ? `${scrollTopWrapper}%` : 0}}>
            <div className="wrapper">
                <h1 className="title">{t("jamoa")}</h1>
                <p className="title-text">{t("jamoa1")}</p>
                <div className={`imgs ${scrollTop == 0 && window.innerWidth > 767 && "visibility"}`}>
                    {dataTeams.map((data) => (
                        <div key={data.id} className={`col-3 img${dataTeams.indexOf(data)+1}`} style={{marginTop: window.innerWidth > 767 ? `${-70*(dataTeams.indexOf(data)) + scrollTop/6 < 0 ? -70*(dataTeams.indexOf(data)) + scrollTop/6 : 0}%` : 0}}>
                            <img src={data.photo} alt="img1" />
                        </div>
                    ))}                    
                </div>
            </div>
            <div className="tape"><p style={{marginLeft: `-${scroll}px`}} >chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> *</div>
            <div className="crimson"><p>chinwakil</p> * <p>chinwakil</p></div>
            <div className="tape1"><p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p style={{marginLeft: `-${scroll}px`}} >chinwakil</p> *</div>
            <div className="grey"><p>chinwakil</p> * <p>chinwakil</p></div>
        </div>
    );
};

export default OurTeam;