import React from "react";
import "./Scss/About.scss";
import { useTranslation } from "react-i18next";
import { LocationOn } from "@material-ui/icons";
import Img from "../../assets/images/about.png";
import Map from "../../assets/images/location.png";
import Person from "../../assets/images/person.png";
import { AccountCircle } from "@mui/icons-material";

const About = ({english, russian, uzbek}) => {
  const { t } = useTranslation();

  return (
    <div className="About">
      <div className="links">
        <a href="/#">
          {t("home")}
          <i className="fa fa-angle-double-right"></i>
        </a>
        <a href="#">{t("biz_page")}</a>
      </div>
      <div className="wrapper">
        <div className="landing">
          <h1 className="title">{t("biz_page")}</h1>
          <p className="text">{t("about_us")}</p>
          <h1 className="filliar">{english && "Our branches"} {russian && "Наши филиалы"} {uzbek && "Bizning filiallarimiz"}</h1>
          <div className="fil">
            <p className="text">{english && "Guangzhou"} {russian && "Гуанчжоу"} {uzbek && "Guanchjou"} </p>
            <p className="text">{english && "LU"} {russian && "ЛУ"} {uzbek && "LU"}</p>
            <p className="text">{english && "Tashkent"} {russian && "Ташкент"} {uzbek && "Toshkent"}</p>
          </div>
          <img src={Img} alt="img" className="img" />
          <div className="person-card">
            <div className="col-6">
              <div className="header">
                {/* <img src={Person} alt="person" /> */}
                <AccountCircle className="person-icon" />
                <div className="name">
                  <h3>{t("ceo")}</h3>
                  <p>{t("ceo_key")}</p>
                </div>
              </div>
              <p className="desc">{t("ceo_tagida")}</p>
            </div>
            <div className="location col-4">
              <div className="marker">
                <LocationOn className="icon" />
                <p>{t("adres")}</p>
              </div>
              <img src={Map} alt="map" />
            </div>
          </div>
          <div className="video-card">
            <h3>{t("biz_page_kichkina_name")}</h3>
            <p>{t("biz_page_kichkina_description")}</p>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/mywWE_Ns_ME"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
