import './Scss/Services.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo1 from '../../assets/images/logo1.png';
import React, { useEffect, useState } from 'react';

const Services = ({ english, russian, uzbek, dataServices }) => {

    const [scroll, setScroll] = useState(0);
    const [rotate, setRotate] = useState(0);
    const [marginTop, setMarginTop] = useState(0);

    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                window.addEventListener("scroll", () => {                    
                    if(30 - (window.scrollY - entry.target.offsetTop)/6 > 0 && window.innerWidth > 600){
                        setScroll(window.scrollY - entry.target.offsetTop)                                       
                    } 
                    if(30 - (window.scrollY - entry.target.offsetTop)/6 <= 0 && window.innerWidth > 600){
                        setScroll(180)                                       
                    } 
                    
                    if(window.scrollY - entry.target.offsetTop > 200 && 210 - (window.scrollY - entry.target.offsetTop)/6 > 0 && window.innerWidth > 600){
                        setRotate(window.scrollY - entry.target.offsetTop - 200)                                       
                    }
                    if(window.scrollY - entry.target.offsetTop > 200 && 210 - (window.scrollY - entry.target.offsetTop)/6 <= 0 && window.innerWidth > 600){
                        setRotate(180*6)                                       
                    }
                   
                    if(210 - (window.scrollY - entry.target.offsetTop)/6 < 0){                                                               
                        setMarginTop(210 - (window.scrollY - entry.target.offsetTop)/6)
                    }
                    if(210 - (window.scrollY - entry.target.offsetTop)/6 >= 0){                                                           
                        setMarginTop(0)
                    }                     
                })
            })
        },        
    );

    useEffect(() => {        
        const parent = document.getElementById("ScrollY")        
        observer.observe(parent)
    }, []);

    const {t} = useTranslation();

    return (
        <div className='Services' id='allservices'>
            <div className="wrapper">        
            {/* style={{marginTop: window.innerWidth > 767 ? `${marginTop/2}%` : 0,}} */}
                <h1 className="title" >{t("serv1")}</h1>
                <p className="title-text" >{t("serv2")}</p>
                <div className="services-card">
                {/* style={{marginLeft: window.innerWidth > 767 ? `${scroll != 0 ? 30 - scroll/6 : 100}%` : 0}} */}
                    {dataServices.slice(0,1).map((data) => (
                        <div key={data.id} className="col-12 service">                                                
                        {/* style={{transform: `rotateY(${window.innerWidth > 767 ? 180-rotate/6 : 0}deg)`}} */}
                            <Link to={`/services/${data.id}`} className="body">
                                <div className="col-6 img">
                                    <img src={data.photo} alt="service" />
                                </div>
                                <div className="texts col-6">
                                    <h1 className="name">{english && data.name_en} {russian && data.name_ru} {uzbek && data.name_uz}</h1>
                                    <p className="desc">{english && data.text_en.slice(0,150)}{russian && data.text_ru.slice(0,150)}{uzbek && data.text_uz.slice(0,150)}...</p>
                                </div>
                            </Link>
                            <Link to={`/services/${data.id}`} className="body back">
                                <img src={Logo1} alt="logo" />
                            </Link>
                        </div>
                    ))}
                    {dataServices.slice(1,3).map((data) => (
                        <div key={data.id} className="col-6 service">                            
                         {/* style={{transform: `rotateY(${window.innerWidth > 767 ? 180-rotate/6 : 0}deg)`}} */}
                            <Link to={`/services/${data.id}`} className="body">
                                <div className="col-12 img small-img">
                                    <img src={data.photo} alt="service" />
                                </div>
                                <div className="texts col-12">
                                    <h1 className="name">{english && data.name_en} {russian && data.name_ru} {uzbek && data.name_uz}</h1>
                                    <p className="desc">{english && data.text_en.slice(0,100)}{russian && data.text_ru.slice(0,100)}{uzbek && data.text_uz.slice(0,100)}...</p>
                                </div>
                            </Link>
                            <Link to={`/services/${data.id}`} className="body back">
                                <img src={Logo1} alt="logo" />
                            </Link>
                        </div>
                    ))}
                    {dataServices.slice(3).map((data) => (
                        <div key={data.id} className="col-12 service">                            
                         {/* style={{transform: `rotateY(${window.innerWidth > 767 ? 180-rotate/6 : 0}deg)`}} */}
                            <Link to={`/services/${data.id}`} className="body">
                                <div className="col-6 img">
                                    <img src={data.photo} alt="service" />
                                </div>
                                <div className="texts col-6">
                                    <h1 className="name">{english && data.name_en} {russian && data.name_ru} {uzbek && data.name_uz}</h1>
                                    <p className="desc">{english && data.text_en.slice(0,150)}{russian && data.text_ru.slice(0,150)}{uzbek && data.text_uz.slice(0,150)}...</p>
                                </div>
                            </Link>
                            <Link to={`/services/${data.id}`} className="body back">
                                <img src={Logo1} alt="logo" />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Services;