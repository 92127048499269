import React from 'react';
import './Scss/News.scss';
import LastNews from './LastNews';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ShareOutlined, VisibilityOutlined } from '@mui/icons-material';

const News = ({ english, russian, uzbek, dataNews }) => {

    const {id} = useParams();

    const {t} = useTranslation();

    return (
        <div className='News'>
            <div className="links">
                <a href="/#">{t("home")}<i className="fa fa-angle-double-right"></i></a>
                <a href="#">{t("news_page")}</a>
            </div>
            {dataNews.filter((c) => c.id == id).map((data) => (
                <div key={data.id} className="wrapper news-page">
                    <div className="col-10 cards">
                        <div className="date"><i className="fa fa-calendar-o"></i> {data.date}</div>
                        <h1 className="title">{english && data.name_en}{russian && data.name_ru}{uzbek && data.name_uz}</h1>
                        <p className="desc">{english && data.text_en}{russian && data.text_ru}{uzbek && data.text_uz}</p>
                        <img src={data.photo} alt="news" />
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/mywWE_Ns_ME" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <div className="bottom">
                            <div className="view"><VisibilityOutlined className='eye-icon' /> 231</div>
                            <a href='#' className="share"><ShareOutlined className='share-icon' /> {t("share")}</a>
                            <div className="author"> {t("author")}: <b>{data.author}</b></div>
                        </div>
                    </div>
                </div>
            ))}
            <LastNews uzbek={uzbek} russian={russian} english={english} dataNews={dataNews} other={true} id={id} />
        </div>
    );
};

export default News;