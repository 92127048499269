import './Scss/LastNews.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { East, West } from '@mui/icons-material';

const LastNews = ({ english, russian, uzbek, dataNews, other, id }) => {

    const [active, setActive] = useState(null);
    const [slideIndex, setSlideIndex] = useState(0);

    function increment(){
        if(slideIndex + 4 < dataNews.length){
            setSlideIndex(slideIndex + 4)
            setActive(true)
        }else{
            setSlideIndex(0)
        }
    };

    function decrement(){
        if(slideIndex > 0){
            setSlideIndex(slideIndex - 4)
            setActive(false)
        }else{
            setSlideIndex(dataNews.length - 4)
        }
    };

    const {t} = useTranslation();

    return (
        <div className='LastNews' id='news'>
            <div className="wrapper">                
                <div className={`title ${other && "black-title"}`}>
                    <div className="col-2 next">
                        <p>{t("news1")}</p>
                        {dataNews.length > 4 &&
                            <div className="icons">
                                <West className={`left ${active === false && 'opacity'}`} onClick={() => decrement()} />
                                <East className={`right ${active === true && 'opacity'}`} onClick={() => increment()} />
                            </div>
                        }
                    </div>
                    <div className="col-8">
                        {other &&
                            <h1>{t("other")}</h1>
                        }
                        {!other &&
                            <h1>{t("news")}</h1>
                        }
                    </div>
                    <div className="col-2 next res">
                        <p>{t("news1")}</p>
                        {dataNews.length > 4 &&
                            <div className="icons">
                                <West className={`left ${!active && 'opacity'}`} onClick={() => decrement()} />
                                <East className={`right ${active && 'opacity'}`} onClick={() => increment()} />
                            </div>
                        }
                    </div>
                    <div className="col-2">
                        <div className="date">20.08.2022</div>
                    </div>
                </div>                                             
                <div className="carousel">
                    {dataNews.slice(slideIndex, slideIndex+1).map((data) => (
                        <div key={data.id} className="col-4">
                            <Link to={`/news/${data.id}`} className={`body h-100 nav-link ${other && "other-back"}`}>
                                <div className="texts">
                                    <h5 className="name">{english && data.name_en} {russian && data.name_ru} {uzbek && data.name_uz}</h5>
                                    <p className="desc">{english && data.text_en.slice(0,105)}{russian && data.text_ru.slice(0,105)}{uzbek && data.text_uz.slice(0,105)}...</p>
                                </div>
                                <img src={data.photo} alt="news" />
                            </Link>                                                  
                        </div>                            
                    ))}                                 
                    <div className="col-4">
                        {dataNews.slice(slideIndex+1, slideIndex+3).map((data) => (
                            <Link key={data.id} to={`/news/${data.id}`} className={`body nav-link middle ${other && "other-back"}`}>
                                <div className="texts">
                                    <h5 className="name">{english && data.name_en} {russian && data.name_ru} {uzbek && data.name_uz}</h5>
                                    <p className="desc">{english && data.text_en.slice(0,105)}{russian && data.text_ru.slice(0,105)}{uzbek && data.text_uz.slice(0,105)}...</p>                                
                                </div>
                            </Link>                                                  
                        ))}          
                    </div>                            
                    {dataNews.slice(slideIndex+3, slideIndex+4).map((data) => (
                        <div key={data.id} className="col-4 responsive">
                            <Link to={`/news/${data.id}`} className={`body h-100 nav-link ${other && "other-back"}`}>
                                <div className="texts">
                                    <h5 className="name">{english && data.name_en} {russian && data.name_ru} {uzbek && data.name_uz}</h5>
                                    <p className="desc">{english && data.text_en.slice(0,105)}{russian && data.text_ru.slice(0,105)}{uzbek && data.text_uz.slice(0,105)}...</p>
                                </div>
                                <img src={data.photo} alt="news" />
                            </Link>                                                  
                        </div>                            
                    ))} 
                </div>                         
            </div>
        </div>
    );
};

export default LastNews;