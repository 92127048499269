import './Scss/AboutHome.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LocationOn } from '@material-ui/icons';
import Img from '../../assets/images/person.png';
import React, { useState, useEffect } from 'react';
import { AccountCircle } from '@mui/icons-material';

const AboutHome = () => {

    // scroll lenta

    const [scroll, setScroll] = useState(0)

    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                window.addEventListener("scroll", () => {
                    if(window.scrollY - entry.target.offsetTop < 2 * window.innerWidth){
                        setScroll(window.scrollY - entry.target.offsetTop + window.innerHeight*2/3)                                       
                    }
                })
            })
        },        
    )

    useEffect(() => {        
        const parent = document.getElementById("about")        
        observer.observe(parent)
    }, []);

    const {t} = useTranslation();

    return (
        <div className='AboutHome' id='about'>
            <div className="wrapper">
                <div className="col-12 text-card">
                    <div className="body">
                        <h1 className="title">{t("biz_page")}</h1>
                        <p className="desc">{t("about_us_sub")}</p>
                        <Link to="/about" className='explore' >{t("explore")}</Link>
                    </div>
                    <div className="col-5 person-card">
                        <div className="person">
                            {/* <img src={Img} alt="person" /> */}
                            <AccountCircle className='person-icon' />
                            <h3 className="name">{t("ceo")} <br /><p>{t("ceo_key")}</p></h3>
                        </div>
                        <p className="text">{t("ceo_tagida")}</p>
                        <div className="location"><LocationOn className='icon' />{t("adres")}</div>
                        <div className="youtube">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/mywWE_Ns_ME" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tape"><p style={{marginLeft: `-${scroll}px`}} >chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> *</div>
            <div className="crimson"><p>chinwakil</p> * <p>chinwakil</p></div>
            <div className="tape1"><p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p>chinwakil</p> * <p>chinwakil</p> <p>chinwakil</p> <p style={{marginLeft: `-${scroll}px`}} >chinwakil</p> *</div>
            <div className="grey"><p>chinwakil</p> * <p>chinwakil</p></div>
        </div>
    );
};

export default AboutHome;