import React from 'react';
import './Scss/Tradition.scss';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Tradition = ({ english, russian, uzbek, dataTraditions }) => {

    const settings = {
        className: "slider variable-width",
        dots: true,
        speed: 1000,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        autoplaySpeed: 3500,
        cssEase: "linear"
    };

    const {t} = useTranslation();

    return (
        <div className="Tradition" id='tradition'>
            <div className="wrapper"> 
                <div className="title">
                    <Link to="/tradition" className='fact'>{t("fakt")}</Link>
                    <h1 className="trad">{t("qadryatlar")}</h1>
                </div>
                <Slider {...settings} className='carousel'>
                    {dataTraditions.map((data) => (
                        <div key={data.id} className="carousel-page">
                            <div className="col-4 texts">
                                <h1 className="name">{english && data.name_en} {russian && data.name_ru} {uzbek && data.name_uz}</h1>
                                <p className="desc">{english && data.description_en} {russian && data.description_ru} {uzbek && data.description_uz}</p>                        
                                <h1 className='number'>0{dataTraditions.indexOf(data)+1}</h1>
                            </div> 
                            <div className="col-4 img1">
                                <div className="body">
                                    <img src={data.photo1} alt="img1" />
                                </div>
                            </div> 
                            <div className="col-4 imgs">                            
                                <div className="col-12 body">
                                    <img src={data.photo2} alt="img2" />
                                </div>
                                <div className="col-12 body">
                                    <img src={data.photo3} alt="img3" />
                                </div>                            
                            </div>              
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Tradition;