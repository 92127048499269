import React from 'react';
import './Scss/Partners.scss';
import { useTranslation } from 'react-i18next';

const Partners = ({dataPartners}) => {

    const {t} = useTranslation();

    return (
        <div className='Partners'>
            <div className="wrapper">
                <h1 className="title">{t("hamkor")}</h1>
                <div className="imgs">
                    {dataPartners.map((data) => (
                        <div key={data.id} className="col-3 partner">
                            <div className="body">
                                <img src={data.photo} alt="partner" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Partners;