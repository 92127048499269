import React from 'react';
import './Scss/Contacts.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Contacts = ({ changeModal }) => {

    const [nameValue, setNameValue] = useState("");
    const [numberValue, setNumberValue] = useState("");
    const [invalidName, setInvalidName] = useState(false);
    const [invalidNumber, setInvalidNumber] = useState(false);

    function changeName(e) {
        setNameValue(e.target.value)
        setInvalidName(false)
    };

    function changeNumber(e) {
        setNumberValue(e.target.value)
        setInvalidNumber(false)
    };

    let bot = {
        TOKEN: "5786613465:AAEPOBCMX9CAfl7W200DqTeEBS0PZ1-0pz4",
        chatID: "-1001688527588",
        message: `Ismi: ${nameValue}; %0ATelefon raqami: ${numberValue}`
    };

    function sendMessage() {
        if (nameValue === "") {
            setInvalidName(true)
        } else if (numberValue === "") {
            setInvalidNumber(true)
        } else {
            fetch(`https://api.telegram.org/bot${bot.TOKEN}/sendMessage?chat_id=${bot.chatID}&text=${bot.message} `, {
                method: "GET"
            })
                .then(success => {
                    console.log('send message')
                }, error => {
                    console.log(error)
                })

            changeModal()
            setNameValue("")
            setNumberValue("")
        }
    }; 

    const {t} = useTranslation();

    return (
        <div className='Contacts' id='contacts'>
            <div className="wrapper">
                <div className="col-5 texts">
                    <p className="desc">{t("form_chap")}</p>
                    <h1 className="title">{t("form_chap_katta")}</h1>
                </div>
                <div className='col-7 form'>
                    <div className="body">
                        <input type="text" value={nameValue} className={`form-control ${invalidName && "red-line"}`} onChange={(e) => changeName(e)} placeholder={`${t("name")}`} />
                        <input type="number" value={numberValue} className={`form-control number ${invalidNumber && "red-line"}`} onChange={(e) => changeNumber(e)} placeholder={`${t("phone")}`} />
                        <button onClick={() => sendMessage()} className='send'>{t("send")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;