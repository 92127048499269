import './Scss/Header.scss';
import Slider from 'react-slick';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Header = ({ english, russian, uzbek, dataSliders }) => {

    const [slideIndex, setSlideIndex] = useState(0);

    const settings = {
        dots: true,
        speed: 1000,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: "linear",
        pauseOnHover: false,
        autoplaySpeed: 20000,
        className: "slider variable-width",
        beforeChange: (current, next) => setSlideIndex(next),
    };

    const { t } = useTranslation();

    return (
        <div className={`Header ${dataSliders.length == 0 && "bg-white"}`} style={{ height: window.innerHeight }}>
            <div className="wrapper">
                <Slider {...settings} className="carousel" >
                    {dataSliders.map((data) => (
                        <div key={data.id} className="page">
                            <img src={data.photo} alt="slider" className='image' />
                            <div className="texts">
                                <div className="text__blur">
                                    <h1 className="name">{english && data.name_en} {russian && data.name_ru} {uzbek && data.name_uz}</h1>
                                    <p className="desc">{english && data.tag_en} {russian && data.tag_ru} {uzbek && data.tag_uz}</p>
                                    <div className="link">
                                        <a href={data.url} className='explore' >{t("explore")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <ul className="numbers">
                {dataSliders.map((data) => (
                    <li key={data.id} className={`${slideIndex == dataSliders.indexOf(data) && "dot-active"}`}>{dataSliders.indexOf(data) + 1}</li>
                ))}
            </ul>
        </div>
    );
};

export default Header;