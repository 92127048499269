import './Scss/SliderPage.scss';
import Slider from 'react-slick';
import React, {useState} from 'react';

const SliderPage = ({ english, russian, uzbek, dataTypes }) => {

    // slider

    const [slideIndex, setSlideIndex] = useState(0);

    const settings = {        
        dots: true,
        speed: 1000,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        centerMode: true,        
        slidesToScroll: 1,
        cssEase: "linear",
        pauseOnHover: false,
        autoplaySpeed: 4000,
        centerPadding: "500px",
        beforeChange: (current, next) => setSlideIndex(next),
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    centerPadding: "400px"
                }               
            },
            {
                breakpoint: 1350,
                settings: {
                    centerPadding: "350px"
                }                
            },
            {
                breakpoint: 1200,
                settings: {
                    centerPadding: "300px"
                }                
            },
            {
                breakpoint: 1050,
                settings: {
                    centerPadding: "250px"
                }                
            },
            {
                breakpoint: 800,
                settings: {
                    centerPadding: "200px"
                }
            },
            {
                breakpoint: 700,
                settings: {
                    centerPadding: "150px"
                }
            },
            {
                breakpoint: 500,
                settings: {
                    centerPadding: "100px"
                }
            },
            {
                breakpoint: 390,
                settings: {
                    centerPadding: "80px"
                }
            }
        ]
    };

    return (
        <div className='SliderPage'>
            <Slider {...settings} className='carousel'>
                {dataTypes.map((data, index) => (
                    <div className={slideIndex == index ? "slide slide-active" : "slide"} key={data.id} >
                        <img src={data.photo} alt="plane" />
                        <h1 className="name">{uzbek && data.name_uz} {russian && data.name_ru} {english && data.name_en}</h1>
                        <p className="desc">{english && data.description_en} {russian && data.description_ru} {uzbek && data.description_uz}</p>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SliderPage;