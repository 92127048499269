import React from "react";
import "./Scss/Footer.scss";
import { Link } from "react-router-dom";
import Img2 from "../../assets/images/map.png";
import { useTranslation } from "react-i18next";
import Img1 from "../../assets/images/logo.png";
import Img4 from "../../assets/images/media.png";
import Img3 from "../../assets/images/coding.png";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="Footer">
      <div className="wrapper">
        <div className="footer-tools">
          <div className="col-3 tool">
            <a href="/#">
              <img src={Img1} alt="logo" />
            </a>
            <p className="adress">{t("adres1")}</p>
            <div className="icons">
              <a href="https://m.facebook.com/profile.php?id=100081633141407">
                <i className="fa fa-facebook"></i>
              </a>
              <a href="https://www.instagram.com/chinwakil/">
                <i className="fa fa-instagram"></i>
              </a>
              <a href="https://www.youtube.com/channel/UCwKwM9tDu3PZrdtlT3aYVwQ">
                <i className="fa fa-youtube-play"></i>
              </a>
            </div>
          </div>
          <div className="col-3 tool">
            <ul>
              <li className="title">{t("f1")}</li>
              <li className="link">
                <a href="/#allservices" className="nav-link p-0">
                  {t("f2")}
                </a>
              </li>
              <li className="link">
                <Link to="/about" className="nav-link p-0">
                  {t("f3")}
                </Link>
              </li>
              <li className="link">
                <a href="/#news" className="nav-link p-0">
                  {t("f4")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-3 tool">
            <ul>
              <li className="title">{t("f5")}</li>
              <li className="link">
                <a href="/#tradition" className="nav-link p-0">
                  {t("f6")}
                </a>
              </li>
              <li className="link">
                <a href="/#advantage" className="nav-link p-0">
                  {t("f7")}
                </a>
              </li>
              <li className="link">
                <a href="#contacts" className="nav-link p-0">
                  {t("f8")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-3 tool">
            <iframe
              style={{ borderRadius: "8px" }}
              className="gmap_iframe"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=174&amp;height=132&amp;hl=en&amp;q=158 Mukimi Street, Tashkent, Uzbekistan&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </div>
        </div>
        <div className="copywrite">
          <p className="reserve">{t("all_rights")}</p>
          <div className="developed">
            <p>{t("designed")}</p>
            <img src={Img3} alt="coding" />
            <img src={Img4} alt="media" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
