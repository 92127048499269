import './Scss/SearchProd.scss';
import React, { useEffect, useState } from 'react';

const SearchProd = ({ english, russian, uzbek, dataSlider }) => {

    const [scroll, setScroll] = useState(0);

    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                window.addEventListener("scroll", () => {
                    if(window.scrollY - entry.target.offsetTop < 2 * window.innerWidth){
                        setScroll(window.scrollY - entry.target.offsetTop + window.innerHeight*3/2)                                       
                    }
                })
            })
        },        
    );

    useEffect(() => {        
        const parent = document.getElementById("SearchProd")        
        observer.observe(parent)
    }, []);

    //slider

    const [slideIndex, setSlideIndex] = useState(0);
    const [rotateSlide, setRotateSlide] = useState(1);
    const [transition, setTransition] = useState(false);

    function nextSlide(){
        setRotateSlide(rotateSlide - 1)
        if(slideIndex + 1 < dataSlider.length){
            setSlideIndex(slideIndex + 1)
        }else{
            setSlideIndex(0)
        }
        setTransition(true)
    };

    function prevSlide(){
        setRotateSlide(rotateSlide + 1)
        if(slideIndex > 0){
            setSlideIndex(slideIndex - 1)
        }else{
            setSlideIndex(dataSlider.length - 1)        // -1 qilganimni sababi slideIndex 0 dan boshlandi yani dataSliderning birinchi indexidan, uzunlik esa dataSliderning oxirgi indexidan bittaga ko'p
        }
        setTransition(true)
    };

    const [leftX, setLeftX] = useState(0);
    const [move, setMove] = useState(false);

    function moveHandle(item){
        setMove(item)
        if(item == false && leftX > 0){
            prevSlide()
            setLeftX(0)
        }else if(item == false && leftX < 0){
            nextSlide()
            setLeftX(0)
        }        
    };
    
    function onDragHandle(e){
        if(move === true){
            setLeftX(leftX + e.movementX)
            setTransition(true)
        }
    };

    function changeIndex(item){
        setSlideIndex(item)
        setRotateSlide(1 - item)
    };

    return (
        <div className='SearchProd' id='SearchProd'>            
            <div className="spinner" onMouseDown={() => moveHandle(true)} onMouseMove={(e) => move && onDragHandle(e)} onMouseUp={() => moveHandle(false)} style={{height: `${window.innerWidth > 400 ? window.innerWidth > 500 ? window.innerWidth > 600 ? window.innerWidth > 767 ? scroll*3/2 : scroll : scroll*5/6 : scroll*2/3 : scroll*3/5}px`, width: `${window.innerWidth > 400 ? window.innerWidth > 500 ? window.innerWidth > 600 ? window.innerWidth > 767 ? scroll*3/2 : scroll : scroll*5/6 : scroll*2/3 : scroll*3/5}px`, transform: `rotate(${360/dataSlider.length*rotateSlide + leftX/10}deg)`, transition: `${transition ? 0.5 : 0}s`}} >
                {dataSlider.map((data) => (                    
                    <div key={data.id} className={`body ${slideIndex == dataSlider.indexOf(data) && "active-body"}`} style={{transform: `rotate(${360/dataSlider.length*(dataSlider.indexOf(data) + 1)}deg) translateX(-3vw)`, transformOrigin: `${window.innerWidth > 400 ? window.innerWidth > 500 ? window.innerWidth > 600 ? window.innerWidth > 767 ? scroll*75/window.innerWidth : scroll*50/window.innerWidth : scroll*42/window.innerWidth : scroll*34/window.innerWidth : scroll*31/window.innerWidth}vw`, transition: `${transition ? 0.3 : 0}s`}}>
                        <img style={{transform: `rotate(${-360/dataSlider.length*(dataSlider.indexOf(data) + 1) - 360/dataSlider.length*rotateSlide}deg)`}} src={data.photo} alt="slider" />
                    </div>                    
                ))}
                {dataSlider.map((data) => (                                      
                    <div key={data.id} className={`texts ${dataSlider.indexOf(data) != slideIndex && "opacity-0"}`} style={{transform: `rotate(${0 - (360/dataSlider.length*rotateSlide + leftX/10)}deg)`}}>
                        <h1 className="name">{english && data.name_en} {russian && data.name_ru} {uzbek && data.name_uz}</h1>
                        <p className="desc">{english && data.description_en} {russian && data.description_ru} {uzbek && data.description_uz}</p>
                    </div>                  
                ))}                
                <div className="mirror" style={{transform: `rotate(${!move && 0 - (360/dataSlider.length*rotateSlide - leftX/10)}deg)`}}></div>
            </div>
            <ul className="spinner-dots">
                <button className='prev' onClick={() => prevSlide()}><i className="fa fa-angle-left"></i></button>
                {dataSlider.map((data) => (
                    <li key={data.id} className={`dot ${dataSlider.indexOf(data) == slideIndex && "dot-active"}`}>
                        <button onClick={() => changeIndex(dataSlider.indexOf(data))}></button>
                    </li>
                ))}
                <button className='next' onClick={() => nextSlide()}><i className="fa fa-angle-right"></i></button>
            </ul>
        </div>
    );
};

export default SearchProd;